<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">考古发掘记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="XMBH"
                    label="所属项目"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.XMBH"
                        :disabled="!isEdit || $route.query.JCDXID"
                    >
                        <el-option
                            v-for="(item, index) in KgxmList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.XMMC"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="JLMC"
                    label="记录名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JLMC"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="DJCFJ"
                    label="第几次发掘"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DJCFJ"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="YCYSBM"
                    label="所属遗产要素"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.YCYSBM"
                        :disabled="!isEdit || showWbdw"
                        style="width: calc(100% - 140px);margin-right: 10px;"
                    >
                        <el-option
                            v-for="(item, index) in YcysList"
                            :key="`${index}`"
                            :value="item.BM"
                            :label="item.MC"
                        ></el-option>
                    </el-select>
                    <el-checkbox
                        v-model="checked"
                        :disabled="!isEdit"
                        @change="changeYcys"
                        >暂无遗产要素</el-checkbox
                    >
                </el-form-item>
                <el-form-item
                    v-if="showWbdw"
                    prop="SSWBDW"
                    label="所属文保单位"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.SSWBDW"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in WbdwList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.DWMC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="KGWZMS"
                    label="考古位置描述"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.KGWZMS"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="XMWZSL"
                    label="考古位置"
                    class="from-item-block"
                >
                    <mapDraw
                        :basicMap="taskMap"
                        :drawConf="drawConf"
                        :jsonStr="jsonStr"
                        @update:jsonStr="getJsonStr"
                    >
                    </mapDraw>
                    <Mymap
                        ref="myMap"
                        class="map"
                        :BoundsList="bounds"
                        :showSwitch="false"
                        @initmap="initMap"
                    >
                    </Mymap>
                </el-form-item>
                <el-form-item
                    prop="KSSJ"
                    label="开始时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.KSSJ"
                        type="date"
                        :picker-options="pickerOptionsKSSJ"
                        placeholder="选择开始日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="JSSJ"
                    label="结束时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.JSSJ"
                        type="date"
                        :picker-options="pickerOptionsJSSJ"
                        placeholder="选择结束日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="SFWG"
                    label="是否完工"
                    class="from-item-block"
                >
                    <el-radio-group
                        v-model="formdata.dataDic.SFWG"
                        :disabled="!isEdit"
                    >
                        <el-radio :label="0">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    prop="FJMJ"
                    label="发掘面积(㎡)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.FJMJ"
                        :disabled="!isEdit"
                        @input="toNumber($event, 'FJMJ')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="HTMJ"
                    label="回填面积(㎡)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.HTMJ"
                        :disabled="!isEdit"
                        @input="toNumber($event, 'HTMJ')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="JFTRZE"
                    label="经费投入总额(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JFTRZE"
                        :disabled="!isEdit"
                        @input="toDecimal($event, 'JFTRZE')"
                        @blur="blurMoney('JFTRZE')"
                        @focus="focusMoney('JFTRZE')"
                    ></el-input>
                </el-form-item>
                <!-- @blur="toMoney1($event,'JFTRZE')" -->
                <el-form-item
                    prop="YYFJXCBHDJF"
                    label="用于发掘现场保护的经费(万元)"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YYFJXCBHDJF"
                        :disabled="!isEdit"
                        @input="toDecimal($event, 'YYFJXCBHDJF')"
                        @blur="blurMoney('YYFJXCBHDJF')"
                        @focus="focusMoney('YYFJXCBHDJF')"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="CG" label="成果" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.CG"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import mapDraw from "@comp/map/mapDraw/MapDraw";
export default {
    name: "kgfjjl",
    components: {
        mapDraw,
    },
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1402",
                dataDic: {
                    glycbtid: this.heritageId,
                    XMBH: "",
                    JLMC: "",
                    DJCFJ: "",
                    YCYSBM: "",
                    SSWBDW: "",
                    KSSJ: "",
                    JSSJ: "",
                    SFWG: "",
                    FJMJ: "",
                    HTMJ: "",
                    KGWZMS: "",
                    JFTRZE: "",
                    YYFJXCBHDJF: "",
                    CG: "", // 成果
                    XMWZSL: "",
                    JCDXID: null,
                },
            },
            rules: {
                XMBH: [
                    {
                        required: true,
                        message: "请选择所属项目",
                        trigger: "change",
                    },
                ],
                JLMC: [
                    {
                        required: true,
                        message: "请填写记录名称",
                        trigger: "blur",
                    },
                ],
                DJCFJ: [
                    {
                        required: true,
                        message: "请填写第几次发掘",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            YcysList: [], // 遗产要素列表
            KgxmList: [], // 考古项目列表
            drawConf: {
                position: "topleft",
                show: true,
                draw: {
                    polygon: true,
                    polyline: false,
                    rectangle: true,
                },
            },
            jsonStr: null,
            taskMap: null,
            bounds: [
                [32.085, 118.7227],
                [32.0175, 118.78045],
            ],
            WbdwList: [],
            checked: false,
            showWbdw: false,
            toMoneyProps: ["JFTRZE", "YYFJXCBHDJF"],
            pickerOptionsKSSJ: {
                disabledDate: (time) => {
                    let beginDateVal = new Date(this.formdata.dataDic.JSSJ);
                    if (beginDateVal) {
                        return time.getTime() > beginDateVal;
                    }
                },
            },
            pickerOptionsJSSJ: {
                disabledDate: (time) => {
                    let beginDateVal = new Date(this.formdata.dataDic.KSSJ);
                    if (beginDateVal) {
                        return time.getTime() < beginDateVal - 8.64e7;
                    }
                },
            },
        };
    },
    watch: {
        formdata: {
            deep: true,
            handler: function() {
                if (this.formdata.dataDic.XMWZSL) {
                    this.jsonStr = this.formdata.dataDic.XMWZSL;
                }
            },
        },
    },
    mounted() {
        this.GetYcysData();
        this.getKjxmList();
        this.getWbdwList();
        this.formdata.dataDic.XMBH = this.$route.query.JCDXID;
        this.drawConf.show = this.isEdit;
    },
    methods: {
        ...mapActions(["GetYcysList", "GetGLXList"]),
        async GetYcysData() {
            let res = await this.GetYcysList();
            this.YcysList = res.ResultValue || [];
        },
        // 获得考古项目列表
        async getKjxmList() {
            let res = await this.GetGLXList({
                itemcode: "1401",
                lm: "jcsj",
            });
            this.KgxmList = res.ResultValue || [];
        },
        async getWbdwList() {
            let res = await this.GetGLXList({
                itemcode: "wbdw",
            });
            this.WbdwList = res.ResultValue || [];
        },
        initMap(map) {
            this.taskMap = map;
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            let jsonStr = JSON.parse(JsonStr);
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            if (jsonStr.length === 0) return false;
            let json = jsonStr[jsonStr.length - 1].geometry;
            // if (json.type !== 'Polygon') return false;
            this.pointJson = jsonStr;
            let typeList = ["draw:created", "draw:editstop", "draw:deleted"]; // 创建、编辑、删除
            if (typeList.indexOf(event.type) > -1) {
                this.formdata.dataDic.XMWZSL = JsonStr;
            }
        },
        changeYcys() {
            if (this.checked) {
                this.showWbdw = true;
                this.formdata.dataDic.YCYSBM = "";
            } else {
                this.showWbdw = false;
                this.formdata.dataDic.SSWBDW = "";
            }
        },
        GetDataNext() {
            this.showWbdw = this.formdata.dataDic.SSWBDW ? true : false;
            this.checked = this.formdata.dataDic.SSWBDW ? true : false;
        },
    },
};
</script>

<style scoped>
.map {
    width: 100%;
    position: relative;
    height: 400px;
    float: left;
}
</style>
